// extracted by mini-css-extract-plugin
export var sectionContent = "contact-module--section-content--74310";
export var firstSection = "contact-module--first-section--7af03";
export var formSection = "contact-module--form-section--fc4bd";
export var sectionText = "contact-module--section-text--a4b46";
export var mandatory = "contact-module--mandatory--78fc8";
export var contactForm = "contact-module--contact-form--c9b02";
export var sameRow = "contact-module--same-row--fea1b";
export var formEntity = "contact-module--form-entity--43bda";
export var dangerError = "contact-module--danger-error--42396";
export var submitConfirmation = "contact-module--submit-confirmation--a3317";
export var submitSuccess = "contact-module--submit-success--4f52f";
export var submitFailure = "contact-module--submit-failure--83714";
export var submitBtn = "contact-module--submit-btn--53a08";
export var sectionImage = "contact-module--section-image--3ed5b";
export var loading = "contact-module--loading--98406";
export var pulse = "contact-module--pulse--7ec16";