//import * as React from "react"
import React from 'react';
import { useState } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as contact from './../css/contact.module.css';
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next";

const Contact = () => {
  
  const { t } = useTranslation('contact');
  
  const [formContents, setFormContents] = useState({
    siteName:'Product Management site',
    name:'',
    email:'',
    phone:'',
    company:'',
    city:'',
    state:'',
    country:'',
    message:''
  });

  const [inputErrors, setInputErrors] = useState({
    nameErr: true,
    emailErr: true,
    companyErr: true,
    countryErr: true,
    messageErr: true
  });

  const [submitSuccess, setSubmitSuccess] = useState({
    success: "unset"
  });

  const [submitStatus, setSubtmiStatus] = useState({
    submitWip: false
  });

  const errorText = t("errorText");
  const emailErrorText = t("emailErrorText");
  const submitSuccessText = t("submitSuccessText");
  const submitFailureText = t("submitFailureText");
  const sendingText = t("sendingText");

  function postForm (formData) {
    //const formEndPoint = "http://192.168.0.220:4001/contact";
    const formEndPoint = "https://www.esaloytynoja.com/contact";
    const apiKey ='chSp9J379am866nKRN8i';
    
    const bodyContent = {
      formData,
      apiKey: apiKey
    }
    
    const queryParameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyContent)
    }
    

    //console.log('query parameters: ' + JSON.stringify(queryParameters));

    
    return fetch(formEndPoint, queryParameters)
      .then(response => {
        if (!response.ok) {
            return response.json().then (jsonError => {
              //console.log('ERROR: ' + JSON.stringify(jsonError));
              return jsonError
            })
        }
        return response.json().then(jsonResponse => {
          //console.log('Palvelin vastaa: ' + JSON.stringify(jsonResponse));
          return jsonResponse;
        })
      })
      .catch((err) => {
        //console.log('ERROR: ' + err);
        return err;
      })
    
  }

  function onSubmit(e) {
    

    if (Object.values(inputErrors).includes(true)) {
      e.preventDefault();
      //console.log('Joku pakollinen kenttä puuttuu!!')
    }
    else {
      //console.log('Kaikki tiedo täytetty: ' + Object.values(inputErrors));
      setSubtmiStatus({
        submitWip: true
      });
      
      e.preventDefault();
      //console.log('Tällainen formi lähtee:' + JSON.stringify(formContents));
      const formSubmit = new Promise ((resolve, reject) => {
        resolve(postForm(formContents));
      })

      formSubmit.then((res) => {
        //console.log('Success? ' + JSON.stringify(res));
        if (res.success) {
          setSubmitSuccess({
            success: "yes"
          });
  
          //Clear the form
          setFormContents({
            siteName:'Product Management site',
            name:'',
            email:'',
            phone:'',
            company:'',
            city:'',
            state:'',
            country:'',
            message:''
          });

          // Prevent sending an empty form immediately after successfull sending
          setInputErrors({
            nameErr: true,
            emailErr: true,
            companyErr: true,
            countryErr: true,
            messageErr: true
          });

          setSubtmiStatus({
            submitWip: false
          });
  
        } else {
          setSubmitSuccess({
            success: "no"
          });
          setSubtmiStatus({
            submitWip: false
          });
        }
      }, (err) => {
        //console.log('Failure? ' + JSON.stringify(err));
        setSubtmiStatus({
          submitWip: false
        });
      }) 
    }
  }

  function onFormChange(e) {
    const err = e.target.id +'Err';
    
    setInputErrors((prevErrors) => ({
      ...prevErrors,
        [err]: false
    }));
    
    formContents[e.target.id] = e.target.value;
    setFormContents({...formContents});
    //console.log('Form: ' + JSON.stringify(formContents));

    
    //console.log('inputError nimi: ' + err);

    //Additional check for email format
    if (e.target.id === "email") { //Check there is @ and at least 3 characters in the email string
      
      if (e.target.value.length < 3 || !e.target.value.includes('@')) {
        setInputErrors((prevErrors) => ({
          ...prevErrors,
            [err]: true
        }));
      }
    } else {
      if (formContents[e.target.id] === "") {
        //console.log(`${e.target.id} kenttä tyhjä.`);
        
        setInputErrors((prevErrors) => ({
          ...prevErrors,
            [err]: true
        }));
        
      }
    }

    
  }

  return (
  <Layout>
    <Seo title={t("seoTitle")} />
    <section id={contact.firstSection}>
      <div className={contact.sectionContent}>
        <h1>{t("heading")}</h1>
          <p>{t("section1BusyText")}</p>
        <div className={contact.formSection} >
          
          <form className={contact.contactForm} onSubmit={onSubmit}>
            <div className={contact.formEntity}>
              <label className={inputErrors.nameErr ? contact.mandatory : ""} htmlFor="name">{t("name")}*</label>
              <input
                type="text"
                id="name"
                value={formContents[`name`] || ``}
                onChange={onFormChange}
                />
                <small 
                    className={contact.dangerError}>{inputErrors.nameErr ? errorText : ""}
                </small>
              </div>

            <div className={contact.sameRow}>
              <div className={contact.formEntity}>
                <label className={inputErrors.emailErr ? contact.mandatory : ""} htmlFor="email">{t("email")}*</label>
                <input
                  type="text"
                  id="email"
                  value={formContents[`email`] || ``}
                  onChange={onFormChange}
                  />
                    <small 
                        className={contact.dangerError}>{inputErrors.emailErr ? emailErrorText : ""}
                    </small>
              </div>
            
              <div className={contact.formEntity}>
                <label htmlFor="phone">{t("phone")}</label>
                <input
                  type="text"
                  id="phone"
                  value={formContents[`phone`] || ``}
                  onChange={onFormChange}
                  />
              </div>
            </div>
            
            <div className={contact.formEntity}>
              <label className={inputErrors.companyErr ? contact.mandatory : ""} htmlFor="company">{t("company")}*</label>
              <input
                type="text"
                id="company"
                value={formContents[`company`] || ``}
                onChange={onFormChange}
                />
                  <small 
                      className={contact.dangerError}>{inputErrors.companyErr ? errorText : ""}
                  </small>
            </div>

            <div className={contact.sameRow}>
              <div className={contact.formEntity}>
                <label htmlFor="city">{t("city")}</label>
                <input
                  type="text"
                  id="city"
                  value={formContents[`city`] || ``}
                  onChange={onFormChange}
                  />
                </div>
            
                <div className={contact.formEntity}>
                  <label htmlFor="state">{t("state")}</label>
                  <input
                    type="text"
                    id="state"
                    value={formContents[`state`] || ``}
                    onChange={onFormChange}
                    />
                </div>
            </div>

            <div className={contact.formEntity}>
              <label className={inputErrors.countryErr ? contact.mandatory : ""} htmlFor="country">
                {t("country")}*</label>  
              <input
                type="text"
                id="country"
                value={formContents[`country`] || ``}
                onChange={onFormChange}
                />
                  <small 
                      className={contact.dangerError}>{inputErrors.countryErr ? errorText : ""}
                  </small>
            </div>

            <div className={contact.formEntity}>
              <label className={inputErrors.messageErr ? contact.mandatory : ""} htmlFor="message">{t("message")}*</label>
              <textarea
                type="text"
                id="message"
                value={formContents[`message`] || ``}
                onChange={onFormChange}
                />
                  <small 
                      className={contact.dangerError}>{inputErrors.messageErr ? errorText : ""}
                  </small>
            </div>
            <div className={contact.submitConfirmation}>
              <small 
                className={contact.submitSuccess}>{submitSuccess.success==="yes" ? submitSuccessText : ""}
              </small>
              <small 
                  className={contact.submitFailure}>{submitSuccess.success==="no" ? submitFailureText : ""}
              </small>
              <small 
                className={contact.loading}>{submitStatus.submitWip ? sendingText : ""}
              </small>
            </div>
            <input
              className={contact.submitBtn}
              type="submit"
              value={t("submitBtn")}
              />
              
          </form>
          
          <address>
              <p>Esa Löytynoja</p>
              <p><a href="mailto:esa@esaloytynoja.com">esa@esaloytynoja.com</a></p>
            </address>

        </div>

        
        
      </div>
    </section>

  </Layout>
  )
}

//jemma
//className={contact.submitSuccess}>{submitStatus.submitWip ? "Sending..." : ""}

export default Contact
